let initState = {
  collar: {
    classic: 0,
    simple: 5,
    rollup: 8,
    hood: 10,
    zipperhood: 15,
    sailor: 15,
    band: 5,
  },
  sleeves: {
    setin: 0,
    raglan: 10,
  },
  closure: {
    buttons: 0,
    zipper: 8,
    flap: 20,
    pullover: 0,
  },
  pocket: {
    slashpocket: 0,
    weltpocket: 4,
    flappocket: 5,
    snappocket: 0,
    straightpocket: 0,
    zipperpocket: 4,
  },
  lining: {
    quilt: 0,
    satin: 10,
    fur: 10,
    polarfleece: 10,
    brushedtricot: 10,
    cotton: 8,
    zipout: 0,
  },
  materials: {
    body: {
      cowhideleather: 65,
      meltonwool: 30,
      cottontwill: 25,
      sheepleather: 75,
      nylonmemory: 25,
      cottonfleece: 25,
      softshell: 25,
      satin: 25,
      synthaticleather: 25,
      nylon: 25,
      taffeta: 25,
    },
    sleeves: {
      cowhideleather: 45,
      meltonwool: 25,
      cottontwill: 25,
      sheepleather: 65,
      nylonmemory: 20,
      cottonfleece: 20,
      softshell: 20,
      satin: 20,
      synthaticleather: 25,
      nylon: 25,
      taffeta: 25,
    },
  },
  designs: {
    frontcenter: 20,
    backtop: 20,
    backbottom: 20,
    backmiddle: 25,
    leftchest: 10,
    rightchest: 10,
    leftpocket: 0,
    rightpocket: 0,
    leftsleeve: 10,
    rightsleeve: 10,
    leftsleeveend: 10,
    rightsleeveend: 10,
  },
  sizes: {
    xxs: 0,
    xs: 0,
    s: 0,
    m: 0,
    mtall: 5,
    l: 5,
    ltall: 5,
    xl: 5,
    xltall: 5,
    '2xl': 5,
    '2xltall': 5,
    '3xl': 10,
    '4xl': 10,
    '5xl': 10,
    '6xl': 10,
    custom: 20,
  },
  advance: {
    chestPocket: 5,
    proCuff: 8,
    insertsCount1: 8,
    insertsCount2: 16,
    piping: 10,
  },
};

const pricing = (state = initState) => {
  return state;
};

export default pricing;
