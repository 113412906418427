import React from 'react';
import { connect } from 'react-redux';

import Loader from '../Loader';
import SelectBox from '../SelectBox';
import { selectColor, updateGlobals } from '../../store/actions';

const PartsBase = ['Body', 'Sleeves', 'Pockets', 'Inside Lining', 'Knit Base'];
const PARTS = {
  'Gifts': PartsBase,
  'Varsity Jackets': PartsBase,
  'Bomber Jackets': PartsBase,
  'Hoodies': ['Body', 'Sleeves', 'Pockets', 'Knit Base', 'Lace'],
  'Coach Jackets': ['Body', 'Sleeves', 'Pockets', 'Inside Lining', 'Lace']
}

const Colors = ({
  globals,
  advance,
  defaults,
  styles,
  materials,
  updateGlobals,
  updateColor,
  color
}) => {
  const { parts, colors } = defaults;

  let finalParts = [];
  parts.map((part) => {
    if (PARTS[globals.catName].includes(part.name)) {
      finalParts.push(part);
    }
  })

  if (advance.stripes) {
    finalParts.splice(2, 0, parts.find(a => a.name === 'Sleeves Stripe'));
  }

  if (advance.piping || advance.sleevesPiping) {
    finalParts.splice(2, 0, parts.find(a => a.name === 'Shoulder & Pocket Piping'));
  }

  if (advance.inserts) {
    finalParts.push(parts.find(a => a.name === 'Shoulder Inserts'));
  }

  if (advance.inserts && advance.insertsCount === 2) {
    finalParts.push(parts.find(a => a.name === 'Shoulder Insert Upper'));
  }

  // Styles Push
  if (styles.collar === 'Roll Up') {
    finalParts.push(parts.find(a => a.name === 'Collar Inside'));
    finalParts.push(parts.find(a => a.name === 'Collar Outside'));
  }
  if (styles.collar === 'Hood' || styles.collar === 'Zipper Hood') {
    finalParts.push(parts.find(a => a.name === 'Hood Inside'));
    finalParts.push(parts.find(a => a.name === 'Hood Outside'));
  }
  if (styles.collar === 'Band') {
    finalParts.push(parts.find(a => a.name === 'Band'));
  }
  if (styles.closure === 'Buttons') {
    finalParts.push(parts.find(a => a.name === 'Buttons'));
  }
  if (styles.closure === 'Zipper') {
    finalParts.push(parts.find(a => a.name === 'Zip'));
  }
  if ((styles.knit === 'Single Line' || styles.knit === 'Double Line' || styles.knit === 'Single Line Border' || styles.knit === 'Double Line Border') && globals.catName !== 'Coach Jackets') {
    finalParts.push(parts.find(a => a.name === 'Knit Lines'));
  }
  if (styles.knit === 'Single Line Border' || styles.knit === 'Double Line Border') {
    finalParts.push(parts.find(a => a.name === 'Knit Border'));
  }

  if (parts === 0 && colors === 0) return <Loader msg={'please wait'} />;

  finalParts.sort((a, b) => {
    return a.id - b.id;
  })

  const [currentTab, setCurrentTab] = React.useState(0);
  const [isActive, setIsActive] = React.useState(true);

  const selectColorTab = (index, nick) => {
    if (index == currentTab) {
      setIsActive(!isActive)
    }
    else {
      updateGlobals('3', nick);
      setCurrentTab(index)
    }
  }

  return (
    <>
      {/* {finalParts.map((part, key) => {
        return (
          <div
            key={key}
            className={`cjd-accordin cjd-accordin-${
              globals[3] === part.nick ? 'open' : 'close'
            }`}
            onClick={() => updateGlobals('3', part.nick)}
          >
            <div className="cjd-label-wrapper">{part.name} Color</div>
            <div className="cjd-accordin-wrapper">
              <div className="cjd-select-wrapper cjd-single cjd-colors-select">
                {colors.map(({ name, code, pid, mid, lid }, key) => {
                  const check = JSON.parse(pid);
                  const matCheck = JSON.parse(mid);
                  const lidCheck = JSON.parse(lid);

                  if (
                    check &&
                    matCheck &&
                    check?.includes(part.id) &&
                    matCheck?.includes(materials.body)
                  ) {
                    return (
                      <div key={key}>
                        {lidCheck &&
                        part.nick === 'lining' &&
                        !lidCheck?.includes(styles.lining) ? null : (
                          <SelectBox
                            key={key}
                            type={part.nick}
                            label={code}
                            tooltip={name}
                            current={colors.body}
                            colors={true}
                            dispatch={(type, label) =>
                              updateColor(type, label)
                            }
                          />
                        )}
                      </div>
                    );
                  } else {
                    return false;
                  }
                })}
              </div>
            </div>
          </div>
        );
      })} */}
      {finalParts.map((part, key) => {
        const result = colors.find(({ code }) => code === color[part.nick]);
        return (
          <div className='control-panel-color'>
            <div className={`control-box-color ${globals[3] === part.nick ? 'open' : 'close'}`} onClick={() => selectColorTab(key, part.nick)}>
              <span class="step-title">
                <strong>{part.name}</strong> {result.name}
              </span>
              <svg
                height={15}
                width={15}
                viewBox="0 0 24 24"
                fill="#8089a2"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    d="M6 12H18M12 6V18"
                    stroke="#8089a2"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </g>
              </svg>
            </div>
            {(isActive && currentTab == key) && (
              <div className="cjd-accordin-wrapper">
                <div className="cjd-select-wrapper cjd-single cjd-colors-select">
                  {colors.map(({ name, code, pid, mid, lid }, key) => {
                    const check = JSON.parse(pid);
                    const matCheck = JSON.parse(mid);
                    const lidCheck = JSON.parse(lid);

                    if (
                      check &&
                      matCheck &&
                      check?.includes(part.id) &&
                      matCheck?.includes(materials.body)
                    ) {
                      return (
                        <div key={key}>
                          {lidCheck &&
                            part.nick === 'lining' &&
                            !lidCheck?.includes(styles.lining) ? null : (
                            <SelectBox
                              key={key}
                              type={part.nick}
                              label={code}
                              tooltip={name}
                              current={colors.body}
                              colors={true}
                              dispatch={(type, label) =>
                                updateColor(type, label)
                              }
                            />
                          )}
                        </div>
                      );
                    } else {
                      return false;
                    }
                  })}
                </div>
              </div>
            )}

          </div>
        );
      })}
    </>
  );
};

const mapStateToProps = (state) => ({
  defaults: state.defaults,
  globals: state.globals,
  advance: state.advance,
  styles: state.styles,
  color: state.colors,
  materials: state.materials,
});

const mapDispatchToProps = (dispatch) => ({
  updateColor: (key, val) => dispatch(selectColor(key, val)),
  updateGlobals: (key, val) => dispatch(updateGlobals(key, val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Colors);
