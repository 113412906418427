import { updateGlobals } from './'
import { apiCall } from '../../utils'

export const defaultUpdates = (key, val) => ({
  type: 'UPDATE_DEFAULTS',
  data: { key, val }
})

export const updateDefaults = () => {
  return (dispatch) => {
    apiCall({ action: 'cjd_defaults' })
      .then(res => {
        const data = res.data;
        Object.keys(data).map( val => {
          dispatch( defaultUpdates(val, data[val]) )
          return true
        } )
        dispatch( updateGlobals('loading', false) )
      });
  }
}