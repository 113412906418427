import React from "react";
import { connect } from "react-redux";
import WebFont from "webfontloader";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import {
  currentTab,
  changePose,
  selectMaterial,
  activeSidebar,
  updateGlobals,
  modalState,
} from "./store/actions";

import Header from "./components/Header";

import Loader from "./components/Loader";
import Jacket from "./components/Jacket";
import JacketBack from "./components/Jacket/back";
import JacketLeft from "./components/Jacket/left";
import JacketRight from "./components/Jacket/right";

import Coach from "./components/coach";
import CoachBack from "./components/coach/back";
import CoachLeft from "./components/coach/left";
import CoachRight from "./components/coach/right";

import Styles from "./components/Styles";
import Materials from "./components/Materials";
import Colors from "./components/Colors";
import Designs from "./components/Designs";
import Sizes from "./components/Sizes";
import Advance from "./components/Advance";
import PopUp from "./components/Modal/popup";
import SaveDesign from "./components/ModalSave";

import ViewFront from "./assets/images/view-front.svg";
import ViewBack from "./assets/images/view-back.svg";
import ViewSide from "./assets/images/view-side.svg";

import BadgeIcon from "./components/Icons/badgeIcon";
import StyleIcon from "./components/Icons/styleIcon";
import AdvanceIcon from "./components/Icons/advanceIcon";
import ColorIcon from "./components/Icons/colorsIcon";
import DesignIcon from "./components/Icons/designIcon";
import SizeIcon from "./components/Icons/sizeIcon";
import HamburgerIcon from "./components/Icons/hamburgerIcon";
import JacketIcon from "./components/Icons/jacketIcon";

import "./css/App.scss";

WebFont.load({
  google: {
    families: [
      "Source+Sans+Pro",
      "Courgette",
      "Cutive",
      "Graduate",
      "Lobster+Two",
      "Merienda+One",
      "Montserrat",
      "Open+Sans",
      "Oswald",
      "Pinyon+Script",
      "Satisfy",
    ],
  },
});

const App = ({
  globals,
  jackets,
  styles,
  materials,
  colors,
  designs,
  sizes,
  activeTab,
  changePose,
  popup,
  selectMaterial,
  pricing,
  activeSidebar,
  advance,
  updateGlobals,
  modalState,
  defaults,
}) => {
  if (globals.loading)
    return <Loader msg="Wait while we prepare the custom experience for you" />;
  const [openSideBar, setOpenSideBar] = React.useState(true);
  const svgContainerStyle = { paddingRight: "10px", paddingLeft: "20px" };
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);
  const [currentTab, setCurrentTab] = React.useState(2);
  const [isActive, setIsActive] = React.useState(true);

  const onClickTab = (value) => {
    if (value == currentTab) {
      setIsActive(!isActive);
    } else {
      setCurrentTab(value);
    }
  };

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Header />

      <div className="cjd-main">
        {/* <div className={`${openSideBar ? 'cjd-sidebar-wrapper-open' : 'cjd-sidebar-wrapper'}`}>
          
        </div> */}

        <div style={{ width: "25%", marginTop: "5rem" }}>
          <div className="control-box" onClick={() => onClickTab(1)}>
            <span class="step-title">
              <strong>Materials</strong> Style
            </span>
            <svg
              height={15}
              width={15}
              viewBox="0 0 24 24"
              fill="#8089a2"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M6 12H18M12 6V18"
                  stroke="#8089a2"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </g>
            </svg>
          </div>
          {isActive && currentTab == 1 && (
            <div className="control-box-control">
              <Materials />
            </div>
          )}

          <div className="control-box" onClick={() => onClickTab(2)}>
            <span class="step-title">
              <strong>Advance</strong> Options
            </span>
            <svg
              height={15}
              width={15}
              viewBox="0 0 24 24"
              fill="#8089a2"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M6 12H18M12 6V18"
                  stroke="#8089a2"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </g>
            </svg>
          </div>
          {isActive && currentTab == 2 && (
            <div className="control-box-control">
              <Advance />
            </div>
          )}

          <div className="control-box" onClick={() => onClickTab(3)}>
            <span class="step-title">
              <strong>Add</strong> Colors
            </span>
            <svg
              height={15}
              width={15}
              viewBox="0 0 24 24"
              fill="#8089a2"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M6 12H18M12 6V18"
                  stroke="#8089a2"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </g>
            </svg>
          </div>
          {isActive && currentTab == 3 && (
            <div className="control-box-control">
              <Colors />
            </div>
          )}

          <div className="control-box" onClick={() => onClickTab(4)}>
            <span class="step-title">
              <strong>Add</strong> Design
            </span>
            <svg
              height={15}
              width={15}
              viewBox="0 0 24 24"
              fill="#8089a2"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M6 12H18M12 6V18"
                  stroke="#8089a2"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </g>
            </svg>
          </div>
          {isActive && currentTab == 4 && (
            <div className="control-box-control">
              <Designs />
            </div>
          )}

          <div className="control-box" onClick={() => onClickTab(5)}>
            <span class="step-title">
              <strong>Select</strong> Size
            </span>
            <svg
              height={15}
              width={15}
              viewBox="0 0 24 24"
              fill="#8089a2"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M6 12H18M12 6V18"
                  stroke="#8089a2"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </g>
            </svg>
          </div>
          {isActive && currentTab == 5 && (
            <div className="control-box-control">
              <Sizes />
            </div>
          )}

        </div>


        <div className="cjd-content-wrapper">
          <div className="cjd-jacket-guides">
            <span>Guides</span>
            <div
              href="#"
              className={`cjd-toggle-guides ${globals.guides && "cjd-guides-on"
                }`}
              onClick={() => updateGlobals("guides", !globals.guides)}
            >
              {globals.guides ? "ON" : "OFF"}
            </div>
          </div>

          {globals.coach ? (
            <>
              {globals.pose === "front" && <Coach />}
              {globals.pose === "back" && <CoachBack />}
              {globals.pose === "left" && <CoachLeft />}
              {globals.pose === "right" && <CoachRight />}
            </>
          ) : (
            <>
              <Jacket pose={globals.pose === "front"} />
              <JacketBack pose={globals.pose === "back"} />
              <JacketLeft pose={globals.pose === "left"} />
              <JacketRight pose={globals.pose === "right"} />
            </>
          )}
        </div>

        <div className="cjd-jacket-nav outer">
          <div
            className={`cjd-nav-item ${globals.pose === "front" && "cjd-active-nav"
              }`}
            onClick={() => changePose("front")}
          >
            <img src={ViewFront} alt="Front View" />
          </div>
          <div
            className={`cjd-nav-item ${globals.pose === "back" && "cjd-active-nav"
              }`}
            onClick={() => changePose("back")}
          >
            <img src={ViewBack} alt="Front Back" />
          </div>
          <div
            className={`cjd-nav-item ${globals.pose === "left" && "cjd-active-nav"
              }`}
            onClick={() => changePose("left")}
          >
            <img src={ViewSide} alt="Left Side" />
          </div>
          <div
            className={`cjd-nav-item ${globals.pose === "right" && "cjd-active-nav"
              }`}
            onClick={() => changePose("right")}
          >
            <img
              src={ViewSide}
              alt="Right Side"
              style={{ transform: "scaleX(-1)" }}
            />
          </div>
        </div>
      </div>

      <PopUp />
      <SaveDesign />
    </>
  );
};

const mapStateToProps = (state) => ({
  globals: state.globals,
  defaults: state.defaults,
  jackets: state.jackets,
  designs: state.designs,
  styles: state.styles,
  materials: state.materials,
  colors: state.colors,
  sizes: state.sizes,
  popup: state.popup,
  pricing: state.pricing,
  advance: state.advance,
});

const mapDispatchToProps = (dispatch) => ({
  activeTab: (tab) => dispatch(currentTab(tab)),
  changePose: (val) => dispatch(changePose(val)),
  selectMaterial: (val) => dispatch(selectMaterial(val)),
  activeSidebar: (idx) => dispatch(activeSidebar(idx)),
  updateGlobals: (key, val) => dispatch(updateGlobals(key, val)),
  modalState: (key, val) => dispatch(modalState(key, val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
