import React from 'react'

export const SetIn = ({ color, colorPicker }) => (
  <g onClick={ () => colorPicker('sleeves') } className="cjd-color-hover" fill="#fff" fillRule="evenodd"
  stroke={color == "#000000" ? "#ffffff" : "#231f20"}
    strokeMiterlimit="2.6131"
    strokeWidth="1px">

    <path
      d="M123.64,287.73c-11.33-49.55-28.13-101.91-52.15-157.62C45.61,187.07,26.32,250,4.71,307.93c-5.82,15.62-.69,19.85-2.43,34.59a279.7,279.7,0,0,0-1.13,55.94c.78,9.43,4.34,20.08,6.13,29.13a290,290,0,0,1,4.91,37.63c.1,2.07,4.48,2.18,5,4.07.77,2.55-3,7-1.95,9.49,5.34,12,14.53,15,20.13,23.62l63.75-10.88c-.63-11.2,2.21-24.93,1.23-35.67-.61-6.66-5.27-12.32-5.86-24.37-.07-1.23-.13-2.45-.18-3.68-.69-14.67.15-29.7-.4-44.55-.12-3.31-3.25-5.67-3.51-9-.23-2.85,3.62-5.67,3.71-8.62.08-2.43-4.32-4.12-4.29-6.63,0-3.22,5.1-1.77,5.4-3.76.41-2.75-4.53-4.15-4.66-6.58,1-1.78,4.63-1.88,5.44-3.78,7.16-16.84,21.4-40.63,27.59-57.19Z"
      data-name="right" fill={color} />
    <path
      d="M390.61,287.73c11.33-49.55,28.13-101.91,52.15-157.62,25.88,57,45.17,119.91,66.78,177.82,5.82,15.62.69,19.85,2.43,34.59a279.7,279.7,0,0,1,1.13,55.94c-.78,9.43-4.34,20.08-6.13,29.13a290,290,0,0,0-4.91,37.63c-.11,2.07-4.48,2.18-5.05,4.07-.78,2.55,3,7,1.94,9.49-5.33,12-14.52,15-20.12,23.62l-63.75-10.88c.63-11.2-2.21-24.93-1.23-35.67.61-6.66,5.27-12.32,5.86-24.37.07-1.23.13-2.45.18-3.68.69-14.67-.16-29.7.4-44.55.12-3.31,3.25-5.67,3.51-9,.23-2.85-3.62-5.67-3.71-8.62-.08-2.43,4.32-4.12,4.29-6.63-.05-3.22-5.1-1.77-5.4-3.76-.41-2.75,4.53-4.15,4.66-6.58-1.05-1.78-4.63-1.88-5.44-3.78-7.16-16.84-21.4-40.63-27.59-57.19Z"
      data-name="left" fill={color} />
  </g>
)

export const Raglan = ({ color, colorPicker }) => (
  <g id="sleeves-raglan" onClick={ () => colorPicker('sleeves') } className="cjd-color-hover" transform="translate(0, 72)" stroke={color == "#000000" ? "#ffffff" : "#231f20"}>
    <path id="right" d="M194.74,5.93c-8.32,1.34-22,5.75-32.69,8.34-14.21,3.49-23.43,5.66-37.29,10.85-4.14,1.57-8,3.07-11.92,4.78C95.22,37.61,82.84,47,77.25,60.42c-25.88,57-45.17,119.91-66.73,177.83-5.82,15.62-.69,19.85-2.43,34.59A278.81,278.81,0,0,0,7,328.77c.78,9.43,4.34,20.09,6.13,29.14A288.54,288.54,0,0,1,18,395.53c.11,2.07,4.48,2.19,5.05,4.07.78,2.55-3,7-1.94,9.5,5.33,12,14.52,15,20.12,23.61L105,421.84c-.63-11.21,2.21-24.94,1.23-35.68-.61-6.65-5.27-12.31-5.86-24.37-.07-1.22-.12-2.45-.18-3.68-.69-14.67.16-29.69-.4-44.55-.12-3.3-3.25-5.67-3.51-8.95-.23-2.85,3.62-5.67,3.71-8.62.08-2.44-4.32-4.13-4.29-6.64,0-3.21,5.1-1.77,5.4-3.76.42-2.75-4.53-4.15-4.66-6.57,1-1.78,4.63-1.88,5.44-3.78,7.16-16.85,21.4-40.63,27.59-57.2,0-131.71-.3-170.71,88.07-214.77C209.68,4.17,200.73,5,194.74,5.93Z" transform="translate(-5.81 -2.02)" fill={color} stroke={color == "#000000" ? "#ffffff" : "#231f20"} strokeMiterlimit="2.61" strokeWidth="1" />
    <path id="left" d="M308.48,2.27C396.85,46.33,396.55,85.33,396.55,217c6.19,16.57,20.43,40.35,27.59,57.2.81,1.9,4.39,2,5.44,3.78-.13,2.42-5.08,3.82-4.66,6.57.3,2,5.4.55,5.4,3.76,0,2.51-4.37,4.2-4.29,6.64.09,2.95,3.94,5.77,3.71,8.62-.26,3.28-3.39,5.65-3.51,8.95-.56,14.86.29,29.88-.4,44.55-.06,1.23-.11,2.46-.18,3.68-.59,12.06-5.25,17.72-5.86,24.37-1,10.74,1.86,24.47,1.23,35.68l63.75,10.87c5.6-8.61,14.79-11.61,20.12-23.61,1.06-2.45-2.72-7-1.94-9.5.57-1.88,4.94-2,5.05-4.07a288.54,288.54,0,0,1,4.91-37.62c1.79-9.05,5.35-19.71,6.13-29.14a278.81,278.81,0,0,0-1.13-55.93c-1.74-14.74,3.39-19-2.43-34.59-21.56-57.92-40.85-120.86-66.73-177.83-5.59-13.38-18-22.81-35.59-30.52-3.92-1.71-7.78-3.21-11.92-4.78-13.86-5.19-23.08-7.36-37.29-10.85-10.69-2.59-24.37-7-32.69-8.34C325.27,4,316.32,3.17,308.48,2.27Z" transform="translate(-5.81 -2.02)" fill={color} stroke={color == "#000000" ? "#ffffff" : "#231f20"} strokeMiterlimit="2.61" strokeWidth="1" />
  </g>
)