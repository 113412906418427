import React from "react";
import { connect } from "react-redux";

import Accordion from "../Accordion";
import SelectBox from "../SelectBox";
//import { Select2 } from "select2-react-component";

import { Select2 } from "../Select2Component/select2.tsx";

import { rspace } from "../../utils";
import { selectMaterial, selectStyle } from "../../store/actions";

const replaceKeyName = (array) => {
  return array.map((item) => {
    const { name, ...rest } = item;
    return { label: name, value: name, ...rest };
  });
};

const Materials = ({
  defaults,
  globals,
  materials,
  styles,
  updateMaterial,
  updateStyle,
  dispatch,
}) => {
  const transformedMaterialBodyArray = replaceKeyName(
    defaults.materials.filter((item) => item.body === "on")
  );
  const transformedMaterialSleeveArray = replaceKeyName(
    defaults.materials.filter(
      (item) => materials.body === item["mat-parent"] && item.sleeves === "on"
    )
  );

  const collarList = [
    { name: "Classic" },
    { name: "Simple" },
    { name: "Roll Up" },
    { name: "Hood" },
    { name: "Zipper Hood" },
    { name: "Band" },
  ];
  const transformedStylesCollarArray = replaceKeyName(collarList);

  const pocketList = [
    { name: "Slash Pocket" },
    { name: "Welt Pocket" },
    { name: "Flap Pocket" },
    { name: "Snap Pocket" },
    { name: "Straight Pocket" },
    { name: "Zipper Pocket" },
  ];

  const transformedStylesPocketArray = replaceKeyName(pocketList);

  const liningList = [
    { name: "Quilt" },
    { name: "Satin" },
    { name: "Cotton" },
  ];

  const transformedStylesLiningArray = replaceKeyName(liningList);


  return (
    <>
      <Accordion>
        <div
          parent={0}
          label="Body Material"
          isOpen={globals[0] === "Body Material"}
        >
          {/* <div className="cjd-select-wrapper "> */}
          <label className="select-label">Body Material</label>
          <Select2
            data={transformedMaterialBodyArray}
            value={materials.body}
            open={(e) => console.log("Open", e)}
            update={(value) => updateMaterial("body", value)}
            isOpen={globals[0] === "Body Material"}
          ></Select2>

          <label className="select-label">Sleeves Material</label>
          <Select2
            data={transformedMaterialSleeveArray}
            value={materials.sleeves}
            open={(e) => console.log("Open", e)}
            update={(value) => updateMaterial("body", value)}
            isOpen={globals[0] === "Sleeve Material"}
          ></Select2>

          <label className="select-label">Sleeves Style</label>
          <div
            className=""
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <button
              data-style="Set-In"
              class={`control-panel-btn ${
                styles.sleeves === "Set-In" && "active"
              }`}
              onClick={() => updateStyle("sleeves", "Set-In")}
            >
              Set-In
            </button>
            <button
              data-style="Raglan"
              class={`control-panel-btn ${
                styles.sleeves === "Raglan" && "active"
              }`}
              onClick={() => updateStyle("sleeves", "Raglan")}
            >
              Raglan
            </button>
          </div>

          <label className="select-label">Collar Style</label>
          <Select2
            data={transformedStylesCollarArray}
            value={styles.collar}
            open={(e) => console.log("Open", e)}
            update={(value) => updateStyle("collar", value)}
            isOpen={globals[1] === "Collar Type"}
          ></Select2>

          <label className="select-label">Slash Pockets</label>
          <Select2
            data={transformedStylesPocketArray}
            value={styles.pocket}
            open={(e) => console.log("Open", e)}
            update={(value) => updateStyle("pocket", value)}
            isOpen={globals[1] === "Pocket Style"}
          ></Select2>

          <label className="select-label">Knit Style</label>
          
          {!globals.bomber && !globals.hoodies && !globals.coach && (
            <div
              parent={1}
              label="Knit / Trim"
              isOpen={globals[1] === "Knit / Trim"}
            >
              <div className="cjd-select-wrapper cjd-single">
                <SelectBox
                  type={"knit"}
                  label={"Plain"}
                  current={styles.knit}
                  style={true}
                  dispatch={(type, label) => updateStyle(type, label)}
                />
                {globals.productId !== 4893 && (
                  <>
                    <SelectBox
                      type={"knit"}
                      label={"Single Line"}
                      current={styles.knit}
                      className={"cjd-sl"}
                      style={true}
                      dispatch={(type, label) => updateStyle(type, label)}
                    />

                    <SelectBox
                      type={"knit"}
                      label={"Single Line Border"}
                      current={styles.knit}
                      className={"cjd-slb"}
                      style={true}
                      dispatch={(type, label) => updateStyle(type, label)}
                    />
                  </>
                )}
                <SelectBox
                  type={"knit"}
                  label={"Double Line"}
                  current={styles.knit}
                  className={"cjd-dl"}
                  style={true}
                  dispatch={(type, label) => updateStyle(type, label)}
                />

                <SelectBox
                  type={"knit"}
                  label={"Double Line Border"}
                  current={styles.knit}
                  className={"cjd-dlb"}
                  style={true}
                  dispatch={(type, label) => updateStyle(type, label)}
                />
              </div>
            </div>
          )}

          <label className="select-label">Front Closure</label>
          <div
            style={{ display: "flex", justifyContent: "space-between" }}>
            <button
              data-style="Buttons"
              class={`control-panel-btn ${
                styles.closure === "Buttons" && "active"
              }`}
              onClick={() => updateStyle("closure", "Buttons")}
            >
              Buttons
            </button>
            <button
              data-style="Zipper"
              class={`control-panel-btn ${
                styles.closure === "Zipper" && "active"
              }`}
              onClick={() => updateStyle("closure", "Zipper")}
            >
              Zipper
            </button>
          </div>

          <label className="select-label">Inside Lining</label>
          <Select2
            data={transformedStylesLiningArray}
            value={styles.lining}
            open={(e) => console.log("Open", e)}
            update={(value) => updateStyle("lining", value)}
            isOpen={globals[1] === "Lining"}
          ></Select2>

          {/* {defaults.materials.map((val) => {
            if (val.body === 'on') {
              if (
                (val.name === 'Nylon' ||
                  val.name === 'Soft Shell' ||
                  val.name === 'Taslan Fabirc') &&
                globals.coach
              ) {
                return (
                  <SelectBox
                    key={val.id}
                    type={'body'}
                    label={val.name}
                    current={materials.body}
                    material={true}
                    className={`cjd-mat cjd-${rspace(val.name)} cjd-material-box`}
                    dispatch={(type, label, sel) => updateMaterial(type, label)}
                  />
                );
              } else if (
                val.name !== 'Nylon' &&
                val.name !== 'Soft Shell' &&
                val.name !== 'Taslan Fabirc' &&
                globals.coach === false
              ) {
                return (
                  <SelectBox
                    key={val.id}
                    type={'body'}
                    label={val.name}
                    current={materials.body}
                    material={true}
                    className={`cjd-mat cjd-${rspace(val.name)} cjd-material-box`}
                    dispatch={(type, label, sel) => updateMaterial(type, label)}
                  />
                  
                );
              }
            } else {
              return true;
            }
          })} */}
          {/* </div> */}
        </div>
        {/* <div
        parent={0}
        label="Sleeve Material"
        isOpen={globals[0] === 'Sleeve Material'}
      >
        <div className="cjd-select-wrapper">
          {defaults.materials.map((val) => {
            if (materials.body === val['mat-parent'] && val.sleeves === 'on') {
              return (
                <SelectBox
                  key={val.id}
                  type={'sleeves'}
                  label={val.name}
                  current={materials.sleeves}
                  material={true}
                  className={`cjd-mat cjd-${rspace(val.name)} cjd-material-box`}
                  dispatch={(type, label, sel) => updateMaterial(type, label)}
                />
              );
            } else {
              if (materials.body === val.name) {
                return (
                  <SelectBox
                    key={val.id}
                    type={'sleeves'}
                    label={val.name}
                    current={materials.sleeves}
                    material={true}
                    className={`cjd-mat cjd-${rspace(val.name)} cjd-material-box`}
                    dispatch={(type, label, sel) => updateMaterial(type, label)}
                  />
                );
              } else {
                return true;
              }
            }
          })}
        </div>
      </div> */}
      </Accordion>
    </>
  );
};

const mapStateToProps = (state) => ({
  defaults: state.defaults,
  globals: state.globals,
  materials: state.materials,
  styles: state.styles,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateMaterial: (key, val) => dispatch(selectMaterial(key, val)),
  updateStyle: (key, val) => dispatch(selectStyle(key, val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Materials);
