import React from 'react';

const PencilIcon = ({  }) => {
    return (
        <svg height="20px" width="20px" version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg"  xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 512 512"   space="preserve" fill='#ffffff'>
            <style type="text/css">
                
            </style>
            <g>
                <path class="st0" d="M494.56,55.774l-38.344-38.328c-23.253-23.262-60.965-23.253-84.226,0l-35.878,35.878l122.563,122.563
		l35.886-35.878C517.814,116.747,517.814,79.044,494.56,55.774z"/>
                <polygon class="st0" points="0,389.435 0,511.998 122.571,511.998 425.246,209.314 302.691,86.751 	" />
            </g>
        </svg>
    );
};

export default PencilIcon;
