import React from 'react'

const PocketZipper = ({ color, laddiesJacket }) => {
  return (
    <g id="pocket_z" data-name="pocket z" style={ laddiesJacket ? { transform: 'translate(135px, 280px)'} : { transform: 'translate(135px, 338px)'} }>
      <g id="left">
        <g>
          <rect x="218.38" y="19.05" width="1.85" height="0.87" transform="translate(7.76 82.9) rotate(-21.86)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="217.76" y="21.26" width="1.85" height="0.87" transform="translate(6.9 82.83) rotate(-21.86)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="219.78" y="22.33" width="1.85" height="0.87" transform="translate(6.64 83.66) rotate(-21.86)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="219.07" y="24.58" width="1.85" height="0.87" transform="translate(5.75 83.56) rotate(-21.86)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="221.09" y="25.66" width="1.85" height="0.87" transform="translate(5.49 84.39) rotate(-21.86)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="220.47" y="27.87" width="1.85" height="0.87" transform="translate(4.63 84.32) rotate(-21.86)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="222.43" y="29.09" width="1.85" height="0.87" transform="translate(4.31 85.13) rotate(-21.86)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="221.82" y="31.29" width="1.85" height="0.87" transform="translate(3.45 85.06) rotate(-21.86)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="223.84" y="32.37" width="1.85" height="0.87" transform="translate(3.19 85.89) rotate(-21.86)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="223.12" y="34.62" width="1.85" height="0.87" transform="translate(2.3 85.79) rotate(-21.86)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="225.18" y="35.8" width="1.85" height="0.87" transform="translate(2.01 86.64) rotate(-21.86)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="224.43" y="37.94" width="1.85" height="0.87" transform="translate(1.16 86.51) rotate(-21.86)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="226.48" y="39.13" width="1.85" height="0.87" transform="translate(0.87 87.36) rotate(-21.86)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="225.87" y="41.33" width="1.85" height="0.87" transform="translate(0 87.29) rotate(-21.86)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="227.79" y="42.45" width="1.85" height="0.87" transform="translate(-0.28 88.09) rotate(-21.86)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="227.18" y="44.66" width="1.85" height="0.87" transform="translate(-1.14 88.02) rotate(-21.86)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="229.23" y="45.84" width="1.85" height="0.87" transform="translate(-1.43 88.87) rotate(-21.86)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="228.52" y="48.08" width="1.85" height="0.87" transform="translate(-2.32 88.77) rotate(-21.86)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="230.54" y="49.16" width="1.85" height="0.87" transform="translate(-2.58 89.59) rotate(-21.86)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="229.82" y="51.41" width="1.85" height="0.87" transform="translate(-3.46 89.49) rotate(-21.86)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="231.84" y="52.49" width="1.85" height="0.87" transform="translate(-3.72 90.32) rotate(-21.86)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="231.23" y="54.69" width="1.85" height="0.87" transform="translate(-4.59 90.25) rotate(-21.86)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="233.19" y="55.92" width="1.85" height="0.87" transform="translate(-4.9 91.07) rotate(-21.86)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="232.57" y="58.12" width="1.85" height="0.87" transform="translate(-5.77 91) rotate(-21.86)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="234.59" y="59.2" width="1.85" height="0.87" transform="translate(-6.02 91.83) rotate(-21.86)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="233.88" y="61.45" width="1.85" height="0.87" transform="translate(-6.91 91.72) rotate(-21.86)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="235.89" y="62.53" width="1.85" height="0.87" transform="translate(-7.17 92.55) rotate(-21.86)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="235.28" y="64.73" width="1.85" height="0.87" transform="translate(-8.03 92.48) rotate(-21.86)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="237.24" y="65.95" width="1.85" height="0.87" transform="translate(-8.35 93.3) rotate(-21.86)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="236.63" y="68.16" width="1.85" height="0.87" transform="translate(-9.21 93.23) rotate(-21.86)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="238.54" y="69.28" width="1.85" height="0.87" transform="translate(-9.49 94.02) rotate(-21.86)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="237.93" y="71.48" width="1.85" height="0.87" transform="translate(-10.36 93.95) rotate(-21.86)" fill={color == '#000000' && "#ffffff"}/>
        </g>
        <path d="M219,12.53a3,3,0,1,1-5.66,2.24c-.33-.88-3-10.93-3-10.93l3.26-1.28C213.75,2.58,218.69,11.65,219,12.53Zm-1.09.28a1.76,1.76,0,0,0-2.34-1,1.9,1.9,0,0,0-1.06,2.31,1.77,1.77,0,0,0,2.34.95A1.84,1.84,0,0,0,217.94,12.81Z" transform="translate(-0.75 -0.15)" fill={color == '#000000' && "#ffffff"} />

        <path d="M219.82,16.8l22.06,55.12.18.61-.51.21-2.76,1.07-.5.21-.18-.61L216.05,18.29Zm21.45,55.3L219.55,17.85l-2.76,1.08,21.72,54.25Z" transform="translate(-0.75 -0.15)" />

        <path d="M244.47,71.47l-28-69.75A2.54,2.54,0,0,0,213.22.31l-3.9,1.56a2.57,2.57,0,0,0-1.42,3.24L235.9,75a2.55,2.55,0,0,0,3.23,1.41l3.9-1.57A2.71,2.71,0,0,0,244.47,71.47Zm-30.87-70a1.4,1.4,0,0,1,1.82.81l28,69.76a1.39,1.39,0,0,1-.82,1.81l-3.9,1.57a1.4,1.4,0,0,1-1.82-.81l-28-69.76A1.39,1.39,0,0,1,209.7,3Z" transform="translate(-0.75 -0.15)" fill={color} />
      </g>
      <g id="right">
        <g>
          <rect x="25.64" y="18.56" width="0.87" height="1.85" transform="translate(-2.47 36.28) rotate(-68.14)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="26.25" y="20.77" width="0.87" height="1.85" transform="translate(-4.13 38.23) rotate(-68.14)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="24.23" y="21.84" width="0.87" height="1.85" transform="translate(-6.4 37.03) rotate(-68.14)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="24.95" y="24.09" width="0.87" height="1.85" transform="translate(-8.04 39.11) rotate(-68.14)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="22.93" y="25.17" width="0.87" height="1.85" transform="translate(-10.31 37.91) rotate(-68.14)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="23.54" y="27.38" width="0.87" height="1.85" transform="translate(-11.97 39.86) rotate(-68.14)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="21.58" y="28.6" width="0.87" height="1.85" transform="translate(-14.33 38.81) rotate(-68.14)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="22.2" y="30.8" width="0.87" height="1.85" transform="translate(-15.99 40.77) rotate(-68.14)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="20.18" y="31.88" width="0.87" height="1.85" transform="translate(-18.26 39.57) rotate(-68.14)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="20.89" y="34.13" width="0.87" height="1.85" transform="translate(-19.9 41.64) rotate(-68.14)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="18.83" y="35.31" width="0.87" height="1.85" transform="translate(-22.29 40.48) rotate(-68.14)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="19.59" y="37.45" width="0.87" height="1.85" transform="translate(-23.8 42.52) rotate(-68.14)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="17.53" y="38.63" width="0.87" height="1.85" transform="translate(-26.19 41.35) rotate(-68.14)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="18.14" y="40.84" width="0.87" height="1.85" transform="translate(-27.85 43.31) rotate(-68.14)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="16.23" y="41.96" width="0.87" height="1.85" transform="translate(-30.1 42.23) rotate(-68.14)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="16.84" y="44.17" width="0.87" height="1.85" transform="translate(-31.76 44.18) rotate(-68.14)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="14.78" y="45.35" width="0.87" height="1.85" transform="translate(-34.15 43.01) rotate(-68.14)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="15.5" y="47.59" width="0.87" height="1.85" transform="translate(-35.78 45.09) rotate(-68.14)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="13.48" y="48.67" width="0.87" height="1.85" transform="translate(-38.05 43.89) rotate(-68.14)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="14.19" y="50.92" width="0.87" height="1.85" transform="translate(-39.69 45.96) rotate(-68.14)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="12.17" y="52" width="0.87" height="1.85" transform="translate(-41.96 44.77) rotate(-68.14)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="12.79" y="54.2" width="0.87" height="1.85" transform="translate(-43.62 46.72) rotate(-68.14)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="10.83" y="55.42" width="0.87" height="1.85" transform="translate(-45.98 45.67) rotate(-68.14)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="11.44" y="57.63" width="0.87" height="1.85" transform="translate(-47.64 47.62) rotate(-68.14)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="9.42" y="58.71" width="0.87" height="1.85" transform="translate(-49.91 46.43) rotate(-68.14)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="10.14" y="60.96" width="0.87" height="1.85" transform="translate(-51.55 48.5) rotate(-68.14)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="8.12" y="62.04" width="0.87" height="1.85" transform="translate(-53.82 47.31) rotate(-68.14)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="8.73" y="64.24" width="0.87" height="1.85" transform="translate(-55.48 49.26) rotate(-68.14)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="6.78" y="65.46" width="0.87" height="1.85" transform="translate(-57.84 48.21) rotate(-68.14)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="7.39" y="67.67" width="0.87" height="1.85" transform="translate(-59.5 50.16) rotate(-68.14)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="5.47" y="68.79" width="0.87" height="1.85" transform="translate(-61.75 49.09) rotate(-68.14)" fill={color == '#000000' && "#ffffff"}/>
          <rect x="6.08" y="70.99" width="0.87" height="1.85" transform="translate(-63.41 51.04) rotate(-68.14)" fill={color == '#000000' && "#ffffff"}/>
        </g>
        <path d="M26.35,12.53A3,3,0,0,0,32,14.77c.33-.88,3-10.93,3-10.93L31.73,2.56C31.63,2.58,26.68,11.65,26.35,12.53Zm1.09.28a1.76,1.76,0,0,1,2.34-1,1.9,1.9,0,0,1,1.06,2.31,1.78,1.78,0,0,1-2.35.95A1.85,1.85,0,0,1,27.44,12.81Z" transform="translate(-0.75 -0.15)" fill={color == '#000000' && "#ffffff"}/>
        <path d="M25.55,16.8,3.5,71.92l-.18.61.51.21,2.76,1.07.5.21.18-.61L29.32,18.29ZM4.11,72.1,25.83,17.85l2.76,1.08L6.87,73.18Z" transform="translate(-0.75 -0.15)"/>
        <path d="M.9,71.47l28-69.75A2.55,2.55,0,0,1,32.16.31l3.9,1.56a2.56,2.56,0,0,1,1.41,3.24L9.48,75a2.56,2.56,0,0,1-3.23,1.41L2.34,74.81A2.72,2.72,0,0,1,.9,71.47Zm30.87-70A1.39,1.39,0,0,0,30,2.23L1.94,72a1.39,1.39,0,0,0,.81,1.81l3.91,1.57a1.39,1.39,0,0,0,1.81-.81l28-69.76A1.39,1.39,0,0,0,35.68,3Z" transform="translate(-0.75 -0.15)" fill={color}/>
      </g>
    </g>
  )
}

export default PocketZipper
