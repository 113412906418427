let initState = [
  {
    id: 0,
    title: 'Jacket # 1',
    active: true,
    svg: '',
    data: {
      materials: {}
    }
  }
]

const jackets = (state = initState, { type, data }) => {
  switch (type) {
    case 'NEW_JACKET':
      return [...state,  Object.assign({}, state[0], { id: state.length, title: `Jacket # ${state.length + 1}`, active: true })]

    case 'UPDATE_JACKET_DATA':
      return state

    case 'UPDATE_PREVIOUS_JACKET':
      return state.map(count => {
        if (count.id === data.key) {
          return {...count, price: data.price, active: false, data: data.obj}
        }
        return count;
      })

    case 'SET_ACTIVE_JACKET':
      return state.map(count => {
        if (count.id === data) {
          return { ...count, active: true }
        } else {
          return { ...count, active: false }
        }

      })

    case 'FIRST_JACKET':
      return state.map(count => {
        if (count.id === 0) {
          return {...count, data: data.obj, price: data.price }
        }
        return count;
      })

    case 'JACKET_DATA':
      return state.map(count => {
        if (count.id === data.key) {
          return {...count, data: data.obj, price: data.price}
        }
        return count;
      })

    case 'SAVE_JACKET_SVG':
      return state.map(count => {
        if (count.id === data.key) {
          return { ...count, [data.part]: data.svg }
        }
        return count;
      })

    case 'RENAME_JACKET':
      return state.map(count => {
        if (count.id === data.key) {
          return {...count, title: data.val}
        }
        return count;
      })

    case 'REMOVE_JACKET':
      let newState = [...state];
      newState.splice(data, 1);
      return newState

    case 'REPLACE_JACKETS':
      return data

    default:
      return state
  }
}

export default jackets