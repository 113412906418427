import React from 'react'

const Sailor = ({ colorPicker, knit, base, lines, border, flip = false }) => (
  <>
    { flip ? (
      <g data-name="collar sailor back" style={{ transform: 'translate(75px, 20px)' }}>
        <defs>
          <clipPath id="clip-path" transform="translate(1.28 -0.16)">
            <path d="M182.48,14.67c-29.34,0-58.68-4.79-74.58-14.35C69.54,39.55,11.43,67.28.26,103.6c11.39,36.4,1,57.56,5.57,95.2,38.76,18,129.72,4.38,176.65,5.18,46.94-.8,137.89,12.78,176.66-5.18,4.6-37.64-5.83-58.8,5.56-95.2C353.53,67.28,295.42,39.55,257.07.32c-15.9,9.56-45.25,14.35-74.59,14.35Z" fill="none" clipRule="evenodd" />
          </clipPath>
        </defs>
        <path d="M182.48,14.67c-29.34,0-58.68-4.79-74.58-14.35C69.54,39.55,11.43,67.28.26,103.6c11.39,36.4,1,57.56,5.57,95.2,38.76,18,129.72,4.38,176.65,5.18,46.94-.8,137.89,12.78,176.66-5.18,4.6-37.64-5.83-58.8,5.56-95.2C353.53,67.28,295.42,39.55,257.07.32c-15.9,9.56-45.25,14.35-74.59,14.35Z" transform="translate(1.28 -0.16)" fill={base} stroke="#231f20" strokeMiterlimit="2.61312" strokeWidth="0.25px" className="cjd-color-hover" onClick={() => colorPicker('base')} />
        <g id="knit" className="cjd-color-hover" onClick={() => colorPicker('lines')}>
          <g clipPath="url(#clip-path)" fill={lines} stroke={(knit === 'Single Line Border' || knit === 'Double Line Border') && border}>

            { (knit === 'Double Line' || knit === 'Double Line Border') &&
              <path id="double" d="M37.93, 208.72l7-.64c-1.6-17.31-1.42-35-1.24-52.65.33-32.87.66-65.67-10.34-96.8L26.71,61c10.6,30,10.27,62.16,9.95,94.39-.18,17.84-.37,35.69,1.27,53.35Zm-13.49,0,7-.64c-1.61-17.31-1.43-35-1.25-52.65.34-32.87.67-65.67-10.34-96.8L13.21,61c10.61,30,10.28,62.16,10,94.39-.18,17.84-.36,35.69,1.28,53.35Zm308.28-.64,7,.64c1.66-17.92,1.37-35.5,1.08-52.92-.51-30.76-1-61,9.5-90.71l-6.67-2.35c-10.92,30.9-10.41,61.75-9.89,93.17.29,17.23.58,34.63-1,52.17Zm-12.72,0,7,.64c1.64-17.66,1.46-35.51,1.28-53.35C328,123.14,327.65,91,338.26,61l-6.67-2.35c-11,31.13-10.68,63.93-10.34,96.8.18,17.66.36,35.34-1.25,52.65Zm-318.4-30-2.88,6.45c29.05,13.05,84.67,10,132.09,7.34,19.5-1.08,37.6-2.08,51.62-1.85h.11c14-.23,32.12.77,51.62,1.85,47.41,2.63,103,5.71,132.08-7.34l-2.88-6.45c-27.48,12.34-82.18,9.31-128.82,6.73-19.53-1.08-37.66-2.08-52.06-1.85-14.39-.23-32.53.77-52.06,1.85C83.79,187.37,29.09,190.4,1.6,178.06Zm0-12.67-2.88,6.45c29.05,13,84.67,10,132.09,7.34,19.5-1.08,37.6-2.09,51.62-1.85h.11c14-.24,32.12.77,51.62,1.85,47.41,2.62,103,5.7,132.08-7.34l-2.88-6.45c-27.48,12.34-82.18,9.31-128.82,6.73C215,171,196.88,170,182.48,170.27c-14.39-.24-32.53.77-52.06,1.85C83.79,174.7,29.09,177.73,1.6,165.39Z" transform="translate(1.28 -0.16)" />
            }

            { (knit === 'Single Line' || knit === 'Single Line Border') &&
              <path id="single" d="M30.8,208.72l7-.64c-1.61-17.31-1.43-35-1.25-52.65.33-32.87.66-65.67-10.34-96.8L19.58,61c10.6,30,10.28,62.16,10,94.39-.18,17.84-.37,35.69,1.27,53.35Zm296.33-.64,7,.64c1.64-17.66,1.46-35.51,1.28-53.35-.33-32.23-.66-64.39,9.95-94.39l-6.67-2.35c-11,31.13-10.68,63.93-10.34,96.8.18,17.66.36,35.34-1.25,52.65ZM1.6,172.44l-2.88,6.45c29.05,13,84.67,10,132.09,7.34,19.5-1.08,37.6-2.08,51.62-1.85h.11c14-.23,32.12.77,51.62,1.85,47.41,2.62,103,5.7,132.08-7.34l-2.88-6.45c-27.48,12.34-82.18,9.31-128.82,6.73-19.53-1.08-37.66-2.08-52.06-1.85-14.39-.23-32.53.77-52.06,1.85-46.63,2.58-101.33,5.61-128.82-6.73Z" transform="translate(1.28 -0.16)" />
            }
          </g>
        </g>
      </g>
    ) : (
      <g data-name="collar sailor">
        <path d="M257,103.68c70.61-9.51,125.8-14.39,182.23,22.68C428.05,90,370,62.31,331.59,23.07c17.1,19.73-50,47.66-74.59,80.61Z" fill={base} fillRule="evenodd" data-name="base" />
        <path d="m257 103.68c24.57-32.95 91.69-60.88 74.59-80.61-31.8 19.14-117.37 19.14-149.17 0-17.1 19.73 50 47.66 74.58 80.61z" fillRule="evenodd" data-name="inside" />
        <path d="m257 103.68c24.57-32.95 91.69-60.88 74.59-80.61-31.8 19.14-117.37 19.14-149.17 0-17.1 19.73 50 47.66 74.58 80.61z" fill="none" stroke="#231f20" strokeMiterlimit="2.6131" strokeWidth=".50003px" data-name="inside border" />
        <path d="M257,103.68c-70.6-9.51-125.8-14.39-182.22,22.68C86,90,144.06,62.31,182.42,23.07c-17.1,19.73,50,47.66,74.58,80.61Z" fill={base} fillRule="evenodd" data-name="base" className="cjd-color-hover" onClick={() => colorPicker('base')} />

        { (knit === 'Single Line' || knit === 'Single Line Border') &&
          <g data-name="knit single" className="cjd-color-hover" onClick={() => colorPicker('lines')}>
            <path
              d="m292.58 71.6 9.6-7.2c35.32 2.71 69.28 8.6 101.64 18.17l9 7.92a733.11 733.11 0 0 0-120.24-18.89z" style={{
                fill: lines,
                stroke: knit === 'Single Line Border' && border
              }} />
            <path
              d="m221.52 71.6-9.6-7.2c-35.32 2.71-69.28 8.6-101.64 18.17l-9 7.92a733.11 733.11 0 0 1 120.24-18.89z"
              data-name="single" style={{
                fill: lines,
                stroke: knit === 'Single Line Border' && border
              }} />
          </g>
        }

        { (knit === 'Double Line' || knit === 'Double Line Border') &&
          <g data-name="knit double" className="cjd-color-hover" onClick={() => colorPicker('lines')}>
            <polygon points="311.85 57.22 388.57 70.66 400.06 79.68 302.39 64.36" data-name="single" style={{
              fill: lines,
              stroke: knit === 'Double Line Border' && border
            }} />
            <polygon points="202.26 57.34 125.96 70.24 114.13 79.5 211.74 64.45" data-name="single" style={{
              fill: lines,
              stroke: knit === 'Double Line Border' && border
            }} />
            <path
              d="m231.79 79.23-11-8c-35.32 2.71-92.64 14.41-125 24l-7 8c39.89-9.62 102.72-21.15 143-24z"
              data-name="single" style={{
                fill: lines,
                stroke: knit === 'Double Line Border' && border
              }} />
            <path
              d="m282.79 79.23 11-8c35.32 2.71 92.64 14.41 125 24l7 8c-39.89-9.62-102.72-21.15-143-24z"
              data-name="single" style={{
                fill: lines,
                stroke: knit === 'Double Line Border' && border
              }} />
          </g>
        }

        <g fill="none" stroke="#231f20" strokeMiterlimit="2.6131" strokeWidth=".50003px">
          <path
            d="M257,103.68c70.61-9.51,125.8-14.39,182.23,22.68C428.05,90,370,62.31,331.59,23.07c17.1,19.73-50,47.66-74.59,80.61Z"
            data-name="border" />
          <path
            d="M257,103.68c-70.6-9.51-125.8-14.39-182.22,22.68C86,90,144.06,62.31,182.42,23.07c-17.1,19.73,50,47.66,74.58,80.61Z"
            data-name="border" />
        </g>
      </g>
    )}
  </>
)

export default Sailor