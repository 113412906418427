// Rookie
import { ReactComponent as A } from '../assets/images/alphabets/rookie/a.svg'
import { ReactComponent as B } from '../assets/images/alphabets/rookie/b.svg'
import { ReactComponent as C } from '../assets/images/alphabets/rookie/c.svg'
import { ReactComponent as D } from '../assets/images/alphabets/rookie/d.svg'
import { ReactComponent as E } from '../assets/images/alphabets/rookie/e.svg'
import { ReactComponent as F } from '../assets/images/alphabets/rookie/f.svg'
import { ReactComponent as G } from '../assets/images/alphabets/rookie/g.svg'
import { ReactComponent as H } from '../assets/images/alphabets/rookie/h.svg'
import { ReactComponent as I } from '../assets/images/alphabets/rookie/i.svg'
import { ReactComponent as J } from '../assets/images/alphabets/rookie/j.svg'
import { ReactComponent as K } from '../assets/images/alphabets/rookie/k.svg'
import { ReactComponent as L } from '../assets/images/alphabets/rookie/l.svg'
import { ReactComponent as M } from '../assets/images/alphabets/rookie/m.svg'
import { ReactComponent as N } from '../assets/images/alphabets/rookie/n.svg'
import { ReactComponent as O } from '../assets/images/alphabets/rookie/o.svg'
import { ReactComponent as P } from '../assets/images/alphabets/rookie/p.svg'
import { ReactComponent as Q } from '../assets/images/alphabets/rookie/q.svg'
import { ReactComponent as R } from '../assets/images/alphabets/rookie/r.svg'
import { ReactComponent as S } from '../assets/images/alphabets/rookie/s.svg'
import { ReactComponent as T } from '../assets/images/alphabets/rookie/t.svg'
import { ReactComponent as U } from '../assets/images/alphabets/rookie/u.svg'
import { ReactComponent as V } from '../assets/images/alphabets/rookie/v.svg'
import { ReactComponent as W } from '../assets/images/alphabets/rookie/w.svg'
import { ReactComponent as X } from '../assets/images/alphabets/rookie/x.svg'
import { ReactComponent as Y } from '../assets/images/alphabets/rookie/y.svg'
import { ReactComponent as Z } from '../assets/images/alphabets/rookie/z.svg'

// Rookie JNL
import { ReactComponent as Aj } from '../assets/images/alphabets/rookie-jnl/a.svg'
import { ReactComponent as Bj } from '../assets/images/alphabets/rookie-jnl/b.svg'
import { ReactComponent as Cj } from '../assets/images/alphabets/rookie-jnl/c.svg'
import { ReactComponent as Dj } from '../assets/images/alphabets/rookie-jnl/d.svg'
import { ReactComponent as Ej } from '../assets/images/alphabets/rookie-jnl/e.svg'
import { ReactComponent as Fj } from '../assets/images/alphabets/rookie-jnl/f.svg'
import { ReactComponent as Gj } from '../assets/images/alphabets/rookie-jnl/g.svg'
import { ReactComponent as Hj } from '../assets/images/alphabets/rookie-jnl/h.svg'
import { ReactComponent as Ij } from '../assets/images/alphabets/rookie-jnl/i.svg'
import { ReactComponent as Jj } from '../assets/images/alphabets/rookie-jnl/j.svg'
import { ReactComponent as Kj } from '../assets/images/alphabets/rookie-jnl/k.svg'
import { ReactComponent as Lj } from '../assets/images/alphabets/rookie-jnl/l.svg'
import { ReactComponent as Mj } from '../assets/images/alphabets/rookie-jnl/m.svg'
import { ReactComponent as Nj } from '../assets/images/alphabets/rookie-jnl/n.svg'
import { ReactComponent as Oj } from '../assets/images/alphabets/rookie-jnl/o.svg'
import { ReactComponent as Pj } from '../assets/images/alphabets/rookie-jnl/p.svg'
import { ReactComponent as Qj } from '../assets/images/alphabets/rookie-jnl/q.svg'
import { ReactComponent as Rj } from '../assets/images/alphabets/rookie-jnl/r.svg'
import { ReactComponent as Sj } from '../assets/images/alphabets/rookie-jnl/s.svg'
import { ReactComponent as Tj } from '../assets/images/alphabets/rookie-jnl/t.svg'
import { ReactComponent as Uj } from '../assets/images/alphabets/rookie-jnl/u.svg'
import { ReactComponent as Vj } from '../assets/images/alphabets/rookie-jnl/v.svg'
import { ReactComponent as Wj } from '../assets/images/alphabets/rookie-jnl/w.svg'
import { ReactComponent as Xj } from '../assets/images/alphabets/rookie-jnl/x.svg'
import { ReactComponent as Yj } from '../assets/images/alphabets/rookie-jnl/y.svg'
import { ReactComponent as Zj } from '../assets/images/alphabets/rookie-jnl/z.svg'

// Ballpark
import { ReactComponent as Ab } from '../assets/images/alphabets/ballpark/a.svg'
import { ReactComponent as Bb } from '../assets/images/alphabets/ballpark/b.svg'
import { ReactComponent as Cb } from '../assets/images/alphabets/ballpark/c.svg'
import { ReactComponent as Db } from '../assets/images/alphabets/ballpark/d.svg'
import { ReactComponent as Eb } from '../assets/images/alphabets/ballpark/e.svg'
import { ReactComponent as Fb } from '../assets/images/alphabets/ballpark/f.svg'
import { ReactComponent as Gb } from '../assets/images/alphabets/ballpark/g.svg'
import { ReactComponent as Hb } from '../assets/images/alphabets/ballpark/h.svg'
import { ReactComponent as Ib } from '../assets/images/alphabets/ballpark/i.svg'
import { ReactComponent as Jb } from '../assets/images/alphabets/ballpark/j.svg'
import { ReactComponent as Kb } from '../assets/images/alphabets/ballpark/k.svg'
import { ReactComponent as Lb } from '../assets/images/alphabets/ballpark/l.svg'
import { ReactComponent as Mb } from '../assets/images/alphabets/ballpark/m.svg'
import { ReactComponent as Nb } from '../assets/images/alphabets/ballpark/n.svg'
import { ReactComponent as Ob } from '../assets/images/alphabets/ballpark/o.svg'
import { ReactComponent as Pb } from '../assets/images/alphabets/ballpark/p.svg'
import { ReactComponent as Qb } from '../assets/images/alphabets/ballpark/q.svg'
import { ReactComponent as Rb } from '../assets/images/alphabets/ballpark/r.svg'
import { ReactComponent as Sb } from '../assets/images/alphabets/ballpark/s.svg'
import { ReactComponent as Tb } from '../assets/images/alphabets/ballpark/t.svg'
import { ReactComponent as Ub } from '../assets/images/alphabets/ballpark/u.svg'
import { ReactComponent as Vb } from '../assets/images/alphabets/ballpark/v.svg'
import { ReactComponent as Wb } from '../assets/images/alphabets/ballpark/w.svg'
import { ReactComponent as Xb } from '../assets/images/alphabets/ballpark/x.svg'
import { ReactComponent as Yb } from '../assets/images/alphabets/ballpark/y.svg'
import { ReactComponent as Zb } from '../assets/images/alphabets/ballpark/z.svg'

// Unknown
import { ReactComponent as Au } from '../assets/images/alphabets/unknown/a.svg'
import { ReactComponent as Bu } from '../assets/images/alphabets/unknown/b.svg'
import { ReactComponent as Cu } from '../assets/images/alphabets/unknown/c.svg'
import { ReactComponent as Du } from '../assets/images/alphabets/unknown/d.svg'
import { ReactComponent as Eu } from '../assets/images/alphabets/unknown/e.svg'
import { ReactComponent as Fu } from '../assets/images/alphabets/unknown/f.svg'
import { ReactComponent as Gu } from '../assets/images/alphabets/unknown/g.svg'
import { ReactComponent as Hu } from '../assets/images/alphabets/unknown/h.svg'
import { ReactComponent as Iu } from '../assets/images/alphabets/unknown/i.svg'
import { ReactComponent as Ju } from '../assets/images/alphabets/unknown/j.svg'
import { ReactComponent as Ku } from '../assets/images/alphabets/unknown/k.svg'
import { ReactComponent as Lu } from '../assets/images/alphabets/unknown/l.svg'
import { ReactComponent as Mu } from '../assets/images/alphabets/unknown/m.svg'
import { ReactComponent as Nu } from '../assets/images/alphabets/unknown/n.svg'
import { ReactComponent as Ou } from '../assets/images/alphabets/unknown/o.svg'
import { ReactComponent as Pu } from '../assets/images/alphabets/unknown/p.svg'
import { ReactComponent as Qu } from '../assets/images/alphabets/unknown/q.svg'
import { ReactComponent as Ru } from '../assets/images/alphabets/unknown/r.svg'
import { ReactComponent as Su } from '../assets/images/alphabets/unknown/s.svg'
import { ReactComponent as Tu } from '../assets/images/alphabets/unknown/t.svg'
import { ReactComponent as Uu } from '../assets/images/alphabets/unknown/u.svg'
import { ReactComponent as Vu } from '../assets/images/alphabets/unknown/v.svg'
import { ReactComponent as Wu } from '../assets/images/alphabets/unknown/w.svg'
import { ReactComponent as Xu } from '../assets/images/alphabets/unknown/x.svg'
import { ReactComponent as Yu } from '../assets/images/alphabets/unknown/y.svg'
import { ReactComponent as Zu } from '../assets/images/alphabets/unknown/z.svg'

const ROOKIE = [ A, B, C, D, E, F, G, H, I, J, K, L, M, N, O, P, Q, R, S, T, U, V, W, X, Y, Z ]
const ROOKIEJNL = [ Aj, Bj, Cj, Dj, Ej, Fj, Gj, Hj, Ij, Jj, Kj, Lj, Mj, Nj, Oj, Pj, Qj, Rj, Sj, Tj, Uj, Vj, Wj, Xj, Yj, Zj ]
const BALLPARK = [ Ab, Bb, Cb, Db, Eb, Fb, Gb, Hb, Ib, Jb, Kb, Lb, Mb, Nb, Ob, Pb, Qb, Rb, Sb, Tb, Ub, Vb, Wb, Xb, Yb, Zb ]
const UNKNOWN = [ Au, Bu, Cu, Du, Eu, Fu, Gu, Hu, Iu, Ju, Ku, Lu, Mu, Nu, Ou, Pu, Qu, Ru, Su, Tu, Uu, Vu, Wu, Xu, Yu, Zu ]

export {
  ROOKIE,
  ROOKIEJNL,
  BALLPARK,
  UNKNOWN
}